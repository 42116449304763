import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { accountService, alertService } from "../../_services";
import { util } from "../../_helpers";
import * as Yup from "yup";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-responsive-list'
import 'react-responsive-list/assets/index.css'

function Roles(props) {

  const user = accountService.userValue;


  const permissions = {
    IS_CATEGORY_READ: false,
    IS_ITEM_READ: false,
    IS_CATEGORY_ADD: false,
    IS_ITEM_ADD: false,
    IS_CATEGORY_EDIT: false,
    IS_ITEM_EDIT: false,
    IS_CATEGORY_DELETE: false,
    IS_ITEM_DELETE: false,
  };

  const [isRole, setIsRole] = useState(true);

  // Roles states
  const [isPermission, setIsPermission] = useState(false);
  const [role, setRole] = useState("");
  const [permission, setPermission] = useState(permissions);
  const [roleData, setRoleData] = useState([]);

  function handleRole() {
    setIsRole(True);
  }

  function getRolesData() {
    accountService.getRoles().then((res) => {
      setRoleData(res);
    });
  }

  useEffect(() => {
    getRolesData();
  }, []);


  const settingPermission = (perm) => {
    //console.log(perm);
    let permssi = permission;
    permssi[`${perm}`] = !permssi[`${perm}`];
    setPermission(permssi);
  };

  const handleRoleSubmit = () => {
    let info = {
      roleName: role,
      permissions: JSON.stringify(permission),
      userId: user.user.id,
    };
    accountService.roles(info).then((res) => {
      getRolesData();
      setIsPermission(false);
    });
  };

  const showRolesList = () => {
    return <Table cellPadding="5px" cellSpacing="3px" style={{ width: "100%",marginTop:"10px" }}>
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th></Th>
                <Th>View</Th>
                <Th>Add</Th>
                <Th>Edit</Th>
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {roleData.length
                ? roleData.map((rol) => {
                    let perms = JSON.parse(rol.permissions);
                    return (
                      <>
                        <Tr>
                          <Td rowSpan="2">
                            <label style={{fontWeight:"bold",color:"#6f6f6f"}}>{rol.roleName}</label>
                          </Td>
                          <Td>
                            <h6 style={{ color: "#9a9696" }}>Categories</h6>
                          </Td>
                          <Td>
                            <input
                              type="checkbox"
                              defaultChecked={perms.IS_CATEGORY_READ}
                              className={'checkbox-common'}
                            />
                          </Td>
                          <Td>
                            <input
                              type="checkbox"
                              defaultChecked={perms.IS_CATEGORY_ADD}
                              className={'checkbox-common'}
                            />
                          </Td>
                          <Td>
                            <input
                              type="checkbox"
                              defaultChecked={perms.IS_CATEGORY_EDIT}
                              className={'checkbox-common'}
                            />
                          </Td>
                          <Td>
                            <input
                              type="checkbox"
                              defaultChecked={perms.IS_CATEGORY_DELETE}
                              className={'checkbox-common'}
                            />
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>
                            <h6 style={{ color: "#9a9696" }}>Items</h6>
                          </Td>
                          <Td>
                            <input
                              type="checkbox"
                              defaultChecked={perms.IS_ITEM_READ}
                              className={'checkbox-common'}
                            />
                          </Td>
                          <Td>
                            <input
                              type="checkbox"
                              defaultChecked={perms.IS_ITEM_ADD}
                              className={'checkbox-common'}
                            />
                          </Td>
                          <Td>
                            <input
                              type="checkbox"
                              defaultChecked={perms.IS_ITEM_EDIT}
                              className={'checkbox-common'}
                            />
                          </Td>
                          <Td>
                            <input
                              type="checkbox"
                              defaultChecked={perms.IS_ITEM_DELETE}
                              className={'checkbox-common'}
                            />
                          </Td>
                        </Tr>
                      </>
                    );
                  })
                : ""}
            </Tbody>
          </Table>
  }

  const addNewRole = () => {
    return <div >
              <div >
                <input
                  type="text"
                  placeholder="Enter Role Name"
                  style={{ width: "200px", marginLeft:'10px',padding:'5px' }}
                  defaultValue={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="form-conTrol input-text"
                />
              </div>

              <Table
                cellPadding="10px"
                cellSpacing="3px"
                style={{ marginTop:'10px' }}
              >
                <Tr>
                  <Th></Th>
                  <Th>View</Th>
                  <Th>Add</Th>
                  <Th>Edit</Th>
                  <Th>Delete</Th>
                </Tr>
                <Tr>
                  <Td>
                    <h6 style={{ color: "#9a9696" }}> Categories</h6>
                  </Td>
                  <Td>
                    <input
                      type="checkbox"
                      className={'checkbox-common'}
                      onChange={() => settingPermission("IS_CATEGORY_READ")}
                    />
                  </Td>
                  <Td>
                    <input
                      type="checkbox"
                      className={'checkbox-common'}
                      onChange={() => settingPermission("IS_CATEGORY_ADD")}
                    />
                  </Td>
                  <Td>
                    <input
                      type="checkbox"
                     className={'checkbox-common'}
                      onChange={() => settingPermission("IS_CATEGORY_EDIT")}
                    />
                  </Td>
                  <Td>
                    <input
                      type="checkbox"
                      className={'checkbox-common'}
                      onChange={() => settingPermission("IS_CATEGORY_DELETE")}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <h6 style={{ color: "#9a9696" }}>Items</h6>
                  </Td>
                  <Td>
                    <input
                      type="checkbox"
                      className={'checkbox-common'}
                      onChange={() => settingPermission("IS_ITEM_READ")}
                    />
                  </Td>
                  <Td>
                    <input
                      type="checkbox"
                      className={'checkbox-common'}
                      onChange={() => settingPermission("IS_ITEM_ADD")}
                    />
                  </Td>
                  <Td>
                    <input
                      type="checkbox"
                      className={'checkbox-common'}
                      onChange={() => settingPermission("IS_ITEM_EDIT")}
                    />
                  </Td>
                  <Td>
                    <input
                      type="checkbox"
                      className={'checkbox-common'}
                      onChange={() => settingPermission("IS_ITEM_DELETE")}
                    />
                  </Td>
                </Tr>
              </Table>
              <div style={{padding:'10px',width:'100%',textAlign:'right'}}>
               <button class="button is-ghost" onClick={() => setIsPermission(false)} style={{marginRight:'20px'}}>Cancel</button>
              <button class="button is-primary"onClick={()=> handleRoleSubmit()} >Save</button>
              </div>
            </div>
  }


  return (
    <div>
      <div
        className="w-100"
      >
        <div>
        <h4 style={{display:'inline-block', padding: "10px 0px" }}>Roles</h4>
        <button
          onClick={() => {
         
           setIsPermission(true);
          }}
          style={{float:'right'}}
          className="text-primary border-only-btn"
        >
          {'+ Add New Role'}
        </button>
     
      </div>
          {isPermission && !props.modalState && props.showModalContent(addNewRole())}

          {showRolesList()}
        </div>
      
    </div>
  );
}

export { Roles };
